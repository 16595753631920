<template>
  <div class="flex items-center gap-0.5">
    <slot name="decrement">
      <button
        :disabled="decrementDisabled"
        class="inline-flex size-6 shrink-0 items-center justify-center rounded-full border border-gray-10 text-primary disabled:opacity-50"
        :aria-label="$t('decrementValue')"
        @click="decrement"
      >
        <UiIcon name="minus" class="size-3" />
      </button>
    </slot>
    <input
      v-model.number="currentValue"
      type="number"
      :disabled="disabled"
      :min="min"
      :max="max"
      :step="step"
      :class="
        cn(
          'h-6 w-full bg-white text-base font-normal text-black placeholder-gray-elements placeholder-opacity-50 focus:outline-none',
          inputClasses,
        )
      "
      @blur="updateValue(Number(($event.target as HTMLInputElement).value))"
      @keydown.esc="
        updateValue(Number(($event.target as HTMLInputElement).value))
      "
      @keydown.enter="
        updateValue(Number(($event.target as HTMLInputElement).value))
      "
      @keydown.up.prevent="increment"
      @keydown.down.prevent="decrement"
    />
    <button
      :disabled="incrementDisabled"
      class="inline-flex size-6 shrink-0 items-center justify-center rounded-full border border-gray-10 text-primary disabled:opacity-50"
      :aria-label="$t('incrementValue')"
      @click="increment"
    >
      <UiIcon name="plus" class="size-3" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    max?: number | string;
    min?: number | string;
    modelValue: number | string;
    step?: number | string;
    precision?: number;
    inputClasses?: string | any[] | object;
  }>(),
  {
    max: Infinity,
    min: -Infinity,
    step: 1,
    precision: 0,
    inputClasses: "",
  },
);

const emit = defineEmits(["update:modelValue"]);

const currentValue = ref(Number(props.modelValue));

const decrementDisabled = ref(false);
const incrementDisabled = ref(false);

const minNumber = computed(() => {
  return Number(props.min);
});

const maxNumber = computed(() => {
  return Number(props.max);
});

const stepNumber = computed(() => {
  return Number(props.step);
});

watchEffect(() => {
  decrementDisabled.value = Number(currentValue.value) === minNumber.value;
  incrementDisabled.value = Number(currentValue.value) === maxNumber.value;
});

watch(
  () => props.modelValue,
  (val) => {
    currentValue.value = Number(val);
  },
);

function increment() {
  if (props.disabled || incrementDisabled.value) {
    return;
  }

  const newVal = currentValue.value + stepNumber.value;

  updateValue(Number(newVal.toFixed(props.precision)));
}
function decrement() {
  if (props.disabled || decrementDisabled.value) {
    return;
  }

  const newVal = currentValue.value + -1 * stepNumber.value;

  updateValue(Number(newVal.toFixed(props.precision)));
}
function updateValue(newVal: number) {
  if (newVal <= minNumber.value) {
    newVal = minNumber.value;
  }
  if (newVal >= maxNumber.value) {
    newVal = maxNumber.value;
  }
  currentValue.value = Number(newVal.toFixed(props.precision));
  emit("update:modelValue", currentValue.value);
}
</script>
