<template>
  <div class="flex w-full items-start gap-5 py-4">
    <nuxt-link
      class="block size-32.5 shrink-0"
      :to="productUrl"
      @click="closeCart"
    >
      <UiLazyImg
        :src="product.image_url || '/images/no-image.webp'"
        :alt="product.title || product.currentTranslation?.title"
      />
    </nuxt-link>
    <div class="flex flex-auto flex-col self-stretch overflow-hidden">
      <div class="mb-1.5 flex w-full gap-2.5">
        <div class="inline-flex flex-auto overflow-hidden">
          <nuxt-link
            class="flex w-full items-start gap-2.5 font-gt-ultra-fine text-sm"
            :to="productUrl"
            @click="closeCart"
          >
            <span>
              {{ product.title }}
            </span>
            <UiButton
              v-if="product.configuration_id && !hideMenu && wishlistInTitle"
              variant-type="icon"
              variant="clear"
              class="size-auto shrink-0 p-0 max-lg:ml-auto"
              :aria-label="$t('addToWishlist')"
              @click.stop.prevent="
                updateWishlist({
                  sku: product.configuration_id,
                  images: [product.image_url || ''],
                  cost: product.sale ? product.sale_cost : product.base_cost,
                  slug: product.slug,
                  configuration_id: product.configuration_id,
                  engraving: {},
                  materials: [],
                  material_categories: [],
                  material_categories_plp_hover_ids: [],
                  translations: product.translations,
                  active_configurations_count:
                    product.active_configurations_count,
                  min_cost: product.min_cost,
                  product_id: product.product_id || product.id,
                })
              "
            >
              <UiIcon
                name="heart"
                class="size-3.5"
                :class="[
                  inWishlist({ sku: product.configuration_id, engraving: {} })
                    ? 'fill-gray-90'
                    : '',
                ]"
                aria-hidden="true"
              />
            </UiButton>
          </nuxt-link>
        </div>
        <div v-if="!hideMenu && !wishlistInTitle" class="flex-shrink-0">
          <UiButton
            v-if="product.configuration_id"
            variant-type="icon"
            variant="clear"
            :aria-label="$t('addToWishlist')"
            @click="
              updateWishlist({
                sku: product.configuration_id,
                images: [product.image_url || ''],
                cost: product.sale ? product.sale_cost : product.base_cost,
                slug: product.slug,
                configuration_id: product.configuration_id,
                engraving: {},
                materials: [],
                material_categories: [],
                material_categories_plp_hover_ids: [],
                translations: product.translations,
                active_configurations_count:
                  product.active_configurations_count,
                min_cost: product.min_cost,
                product_id: product.product_id || product.id,
                vendor_model: product.vendor_model || '',
              })
            "
          >
            <UiIcon
              name="heart"
              class="size-3.5"
              :class="[
                inWishlist({ sku: product.configuration_id, engraving: {} })
                  ? 'fill-gray-90'
                  : '',
              ]"
              aria-hidden="true"
            />
          </UiButton>
        </div>
      </div>
      <div class="mb-3 text-xs font-light text-gray-60">
        <div
          v-if="product?.embedded"
          v-html="product.product_short_description"
        />
        <template v-else-if="!isDiamond">
          <div class="text-xs font-light text-gray-60">
            {{ filteredProductMaterials }}{{ filteredProductOptionsMaterials }}
          </div>
        </template>
      </div>
      <div class="mb-3 text-left text-[15px] font-light text-orange-60">
        {{
          $n(
            Number(product.sale ? product.sale_cost : product.base_cost),
            "currency",
          )
        }}
      </div>
      <UiNumberInput
        v-if="!hideControls"
        v-model="internalQuantity"
        input-classes="w-8 text-center"
        :min="1"
        :max="999"
      >
        <template v-if="internalQuantity === 1" #decrement>
          <UiButton
            variant="clear"
            :loading="checkoutStore.removingIdsLoading.includes(product.id)"
            class="inline-flex size-6 shrink-0 items-center justify-center rounded-full border border-gray-10 p-0 text-primary disabled:opacity-50"
            :aria-label="$t('ariaLabelRemoveItem', { title: product.title })"
            @click="removeCartItemWrap"
          >
            <UiIcon name="trash" class="size-3" />
          </UiButton>
        </template>
      </UiNumberInput>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CartItem } from "~/types/api/cart.types";
import {
  cartMaterialShowListSKU,
  diamondSku,
  propertiesGroupSkus,
  ringSizeSku,
} from "@/configs";
import { useCheckoutStore } from "~/stores/checkout";

const props = defineProps<{
  product: CartItem;
  hideMenu?: boolean;
  showEstimation?: boolean;
  isChild?: boolean;
  productIndex?: number;
  hideControls?: boolean;
  wishlistInTitle?: boolean;
}>();

defineEmits<{
  (e: "showMore"): void;
  (e: "remove"): void;
}>();

const checkoutStore = useCheckoutStore();
const { inWishlist, updateWishlist } = useWishlist();

const localePath = useLocalePathPolyfill();

const { closeCart, removeCartItem, updateCartItem } = useCart();

const { gaRemoveFromCartEvent } = useGoogleAnalyticsEvents();

const { removeFromCartEvent } = useEvents();

const internalQuantity = ref(props.product.quantity);

const unwatch = watchDebounced(
  internalQuantity,
  () => {
    if (
      !internalQuantity.value ||
      checkoutStore.removingIdsLoading.includes(props.product.id)
    )
      return;
    updateCartItem({ ...props.product, quantity: internalQuantity.value });
  },
  { debounce: 500 },
);

watch(
  () => props.product.quantity,
  (newQuantity) => {
    internalQuantity.value = newQuantity;
  },
);

const productUrl = computed(() => {
  if (props.product.options.engagement) {
    if (props.product.options.engagement.product_type === "diamond") {
      return localePath({
        path: `/product/${props.product.slug}`,
        query: {
          configuration_id: props.product.configuration_id,
          pmaterials: props.product.options.engagement.pmaterials,
          dsku: props.product.options.engagement.dsku,
          psku: props.product.options.engagement.psku,
          dShape: props.product.options.engagement.dShape || "",
          dCarat: props.product.options.engagement.dCarat,
          pAllowedShapes: props.product.options.engagement.pAllowedShapes,
          pAllowedCarats: props.product.options.engagement.pAllowedCarats,
          startWith: props.product.options.engagement.startWith,
          flowType: props.product.options.engagement.flowType,
        },
      });
    } else if (props.product.options.engagement.product_type === "product") {
      return localePath({
        path: `/product/${props.product.slug}`,
        query: {
          configuration_id: props.product.configuration_id,
          materials: props.product.options.engagement.pmaterials,
          pmaterials: props.product.options.engagement.pmaterials,
          dsku: props.product.options.engagement.dsku,
          psku: props.product.options.engagement.psku,
          dShape: props.product.options.engagement.dShape || "",
          dCarat: props.product.options.engagement.dCarat,
          pAllowedShapes: props.product.options.engagement.pAllowedShapes,
          pAllowedCarats: props.product.options.engagement.pAllowedCarats,
          startWith: props.product.options.engagement.startWith,
          flowType: props.product.options.engagement.flowType,
        },
      });
    } else {
      return localePath({
        path: "/engagement-ring-settings/review",
        query: {
          psku: props.product.options.engagement.psku,
          dsku: props.product.options.engagement.dsku,
          pmaterials: props.product.options.engagement.pmaterials,
          dShape: props.product.options.engagement.dShape || "",
          dCarat: props.product.options.engagement.dCarat,
          pAllowedShapes: props.product.options.engagement.pAllowedShapes,
          pAllowedCarats: props.product.options.engagement.pAllowedCarats,
          startWith: props.product.options.engagement.startWith,
          flowType: props.product.options.engagement.flowType,
        },
      });
    }
  } else {
    return localePath(
      `/product/${props.product.slug}?configuration_id=${props.product.configuration_id}`,
    );
  }
});

const isDiamond = computed(() => {
  return !!props.product?.categories?.find((el) => el.sku === diamondSku);
});

const filteredProductMaterials = computed(() => {
  const res = props.product.materials.reduce(
    (acc, material) => {
      const constant = cartMaterialShowListSKU.find(
        (item) => item.sku === material.category_sku,
      );

      if (
        constant?.sku === propertiesGroupSkus.stoneSize ||
        constant?.sku === propertiesGroupSkus.caratWeight
      ) {
        acc.push({ title: material.title + " Ct. Tw.", order: constant.order });
      } else if (constant) {
        acc.push({ title: material.title, order: constant.order });
      }

      return acc;
    },
    [] as { title: string; order: number }[],
  );

  res.sort((a, b) => a.order - b.order);

  return res.map((item) => item.title).join(" | ");
});

const filteredProductOptionsMaterials = computed(() => {
  const optionRingSize = props.product?.options?.materials?.find(
    (material) => material.material_category_sku?.sku === ringSizeSku,
  );

  if (optionRingSize) {
    return `${filteredProductMaterials.value ? " | " : ""}Size ${optionRingSize.title}`;
  }

  return "";
});

async function removeCartItemWrap() {
  await removeCartItem(
    props.product.id,
    props.product.diamond ? props.product.diamond[0].id : undefined,
  );
  unwatch();
  gaRemoveFromCartEvent(props.product, props.productIndex);
  removeFromCartEvent({
    product_id: props.product.product_id,
    configuration_id: props.product.configuration_id,
    collection_id: props.product?.collections_ids?.[0] || "",
    product_sku: props.product.vendor_model,
    product_price: props.product?.base_cost,
  });
  if (props.product.diamond?.length) {
    gaRemoveFromCartEvent(props.product.diamond[0], props.productIndex);
    removeFromCartEvent({
      product_id: props.product.diamond[0].id,
      configuration_id: props.product.diamond[0].configuration_id,
      collection_id: props.product.diamond[0]?.collections_ids?.[0] || "",
      product_sku: props.product.diamond[0].vendor_model,
      product_price: props.product.diamond[0]?.base_cost,
    });
  }
}
</script>
